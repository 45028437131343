<template>
    <div class="social-icons">

        <a
        href="https://twitter.com/mojoheadsnft"
        target="_blank"
        class="button color:transparent-white shape:round icon-only">
            <span class="icon"><img src="../assets/images/icon-twitter.svg" /></span>
        </a>

        <a
        href="https://discord.gg/mojoheads"
        target="_blank"
        class="button color:transparent-white shape:round icon-only">
            <span class="icon"><img src="../assets/images/icon-discord.svg" /></span>
        </a>

        <a
        href="https://opensea.io/collection/mojoheads"
        target="_blank"
        class="button color:transparent-white shape:round icon-only">
            <span class="icon"><img src="../assets/images/icon-opensea-white.svg" /></span>
        </a>

    </div>

</template>

<script>

export default {
    name: 'Social Icons',
    components: {
        // HelloWorld
    },
}
</script>

<style lang="scss">
    .social-icons {
        display:  grid;
        grid-template-columns: auto auto auto;
        justify-content: right;

        .button.icon-only {
            width:  2.75em;
            height:  2.75em;

            &:hover {
                &::after {
                    box-shadow: 0 0 0 2px #fbe768 !important;
                }
            }
        }

    }
</style>
