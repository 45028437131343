import {createStore} from "vuex";
import { ethers } from 'ethers'
const Web3 = require('web3')

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import ToastService from '../tecto-ui/vue/services/ToastService.js';

export const namespaced = true

export default createStore({
    state: {
        chain: {
            primaryContractAddress: '0x83Ff0d70bF7eA1955920c136D8a2915BCA5a53B6',
            network: 'mainnet',
            networkId: '0x1',
            chainId: 1,
            etherscan: 'https://etherscan.io/tx/',
            provider: 'https://mainnet.infura.io/v3/2fdf6b77194b497e87bbbb07f5528599',
            infuraKey: '2fdf6b77194b497e87bbbb07f5528599',
            currentCampaignId: 4,
        },
        /*chain: {
            primaryContractAddress: '0xbA307eFA861845e5D013DA9F07a8E97F0718Fc1f',
            network: 'rinkeby',
            networkId: '0x4',
            chainId: 4,
            etherscan: 'https://rinkeby.etherscan.io/tx/',
            provider: 'https://rinkeby.infura.io/v3/2fdf6b77194b497e87bbbb07f5528599',
            infuraKey: '2fdf6b77194b497e87bbbb07f5528599',
            currentCampaignId: 1,
        },*/
        /*chain: {
            primaryContractAddress: '0x3c5068fe7e14adf97cffa9d7dab0693a50f58856',
            network: 'mumbai',
            networkId: '0x80001', //0x3 for ropsten //0x1 for mainnet
            chainId: 80001,
            etherscan: 'https://mumbai.polygonscan.com/tx/',
            provider: 'https://polygon-mumbai.infura.io/v3/2fdf6b77194b497e87bbbb07f5528599',
            currentCampaignId: 1,
        },*/
        accessPassAddress: null,
        currentCampaign: null,
        campaignStates: ['PENDING', 'READY', 'PRESALE', 'ONGOING', 'PAUSED', 'FINISH'],
        hasMetaMask: false,
        abiSuccess: false,
        account: null,
        error: null,
        minting: false,
        tx: null,
        mintSuccess: false,
        ethereum: null,
        mintPass: null,
        mojoHeads: null,
        vipPasses: 0,
        priorityPasses: 0,
        walletConnect: {
            connector: null,
            chainId: null,
            account: null
        },
        connectedProvider: null,
        web3: null
    },
    getters: {
        providers: (state) => state.providers,
        account: (state) => state.account,
        error: (state) => state.error,
        minting: (state) => state.minting,
        parseBigNumber: () => (n) => {
            const raw = String(n);
            const bn = ethers.utils.parseEther(raw);
            return ethers.utils.parseUnits(bn.toString()).toString();
        }
    },
    mutations: {
        setAccessPassAddress(state, address) {
            state.accessPassAddress = address;
        },
        setCurrentCampaign(state, campaign) {
            state.currentCampaign = campaign;
        },
        setHasMetaMask(state, hasMetaMask) {
            state.hasMetaMask = hasMetaMask;
        },
        setAbiSuccess(state, status) {
            state.abiSuccess = status;
        },
        setAccount(state, account) {
            state.account = account;
        },
        setError(state, error) {
            state.error = error;
        },
        setMinting(state, minting) {
            state.minting = minting;
        },
        setTx(state, tx) {
            state.tx = tx;
        },
        setMintSuccess(state, val) {
            state.mintSuccess = val;
        },
        setMintPass(state, abi) {
            state.mintPass = abi;
        },
        setMojoHeads(state, abi) {
            state.mojoHeads = abi;
        },
        setVipPasses(state, count) {
            state.vipPasses = count;
        },
        setPriorityPasses(state, count) {
            state.priorityPasses = count;
        },
        setWalletConnect(state, walletConnect) {
            state.walletConnect = walletConnect;
        },
        setConnectedProvider(state, provider) {
            state.connectedProvider = provider;
        },
        setWeb3(state, web3) {
            state.web3 = web3;
        }
    },
    actions: {
        /**
         * listen for changes to the user's metamask account
         */
        async listenForAccountChanges({ commit, dispatch }) {
            /*
            LISTEN FOR MM CHANGES
             */
            try {
                window.ethereum.on('accountsChanged', async () => {
                    await dispatch('checkIfAccountIsConnected')
                });
            } catch (e) {
                //console.log('USER HAS NO METAMASK');
            }
            /*
            LISTEN FOR WC CHANGES
             */
            try {
                // Subscribe to connection events
                this.state.walletConnect.connector.on("connect", async (error, payload) => {
                    await commit('setConnectedProvider', 'walletconnect');

                    if (error) {
                        throw error;
                    }

                    // Get provided accounts and chainId
                    const { accounts, chainId } = payload.params[0];

                    if (this.state.walletConnect.account === null) { //this can trigger when account already connected
                        await commit('setAccount', accounts[0]);
                        await commit('setWalletConnect', {
                            connector: this.state.walletConnect.connector,
                            chainId: chainId,
                            account: accounts[0]
                        });
                        await commit('setConnectedProvider', 'walletconnect');
                        await dispatch('checkAccessPassBalances');
                    }
                });

                this.state.walletConnect.connector.on("session_update", async (error, payload) => {
                    if (error) {
                        throw error
                    }

                    // Get updated accounts and chainId
                    const { accounts, chainId } = payload.params[0];
                    await commit('setAccount', accounts[0]);
                    await commit('setWalletConnect', {
                        connector: this.state.walletConnect.connector,
                        chainId: chainId,
                        account: accounts[0]
                    });
                    await commit('setConnectedProvider', 'walletconnect');
                    await dispatch('checkAccessPassBalances');
                });

                this.state.walletConnect.connector.on("disconnect", async (error) => {
                    if (error) {
                        throw error;
                    }

                    // Delete connector
                    await commit('setAccount', null);
                    await commit('setWalletConnect', {
                        connector: this.state.walletConnect.connector,
                        chainId: null,
                        account: null
                    });
                    await commit('setConnectedProvider', null);
                    await dispatch('checkAccessPassBalances');
                })
            } catch (e) {
                console.log('error connecting');
                console.log(e);
            }
        },
        /**
         * determine whether use has MetaMask browser extension installed
         */
        async checkMetaMaskInstalled({ commit }) {
            try {
                this.ethereum = window.ethereum;
                if (!this.ethereum) {
                    commit('setHasMetaMask', false);
                } else {
                    commit('setHasMetaMask', true);
                }
            } catch (error) {
                commit('setHasMetaMask', false);
            }
        },
        /**
         * determine whether use has WalletConnect connection established
         */
        async checkWalletConnectConnected({ commit, dispatch }) {
            /*
            WALLETCONNECT INTEGRATION:
            taken from implementation page:
            https://docs.walletconnect.org/quick-start/dapps/client
            */

            // Create a connector
            await commit('setWalletConnect', {
                connector: new WalletConnect({
                    bridge: "https://bridge.walletconnect.org", // Required
                    qrcodeModal: QRCodeModal,
                }),
                chainId: null,
                account: null
            });

            // Check if connection is already established
            if (!this.state.walletConnect.connector.connected) {
                await dispatch('checkAccessPassBalances');
            } else {
                let wc = JSON.parse(JSON.stringify(this.state.walletConnect.connector));
                await commit('setAccount', wc._accounts[0]);
                await commit('setWalletConnect', {
                    connector: this.state.walletConnect.connector,
                    chainId: wc._chainId,
                    account: wc._accounts[0]
                });
                await commit('setConnectedProvider', 'walletconnect');
                await dispatch('checkAccessPassBalances');
            }
        },
        /**
         * check which chain the user is connected to, and request
         * proper network if not correct
         */
        async checkConnectedChain({ commit, dispatch }) {
            await dispatch('resetError')
            if (this.state.chain.network !== 'mumbai') { //todo: debug mumbai network id mismatch
                if (this.state.connectedProvider === 'walletconnect') {
                    if (this.state.walletConnect.chainId !== this.state.chain.chainId) {
                        ToastService.add({
                            typ: 'danger',
                            msg: 'Connect to ' + this.state.chain.network,
                            dur: 6,
                        });
                    }
                } else {
                    if (!this.ethereum.overrideIsMetaMask) { //this is a non-metamask wallet (coinbase wallet, etc)
                        let chainId = await this.ethereum.request({method: 'eth_chainId'});
                        if (chainId !== this.state.chain.networkId) {
                            if (!(await dispatch('switchNetwork'))) {
                                console.log('trouble switching network')

                                await commit(
                                    'setError',
                                    'chain-incorrect'
                                );
                            } else {
                                console.log('okay network switched')
                                return true
                            }
                        }
                    } else {
                        return true
                    }
                }
            }
        },
        /**
         * request that user change their MetaMask chain
         * to the proper chain
         */
        async switchNetwork() {
            try {
                await this.ethereum.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: this.state.chain.networkId }],
                })
                return true
            } catch (switchError) {
                console.log('switch error')
                console.log(switchError)
                return false
            }
        },
        /**
         * determine whether user has connected a
         * MetaMask account
         */
        async checkIfAccountIsConnected({commit,dispatch}) {
            if (this.state.connectedProvider !== 'walletconnect') {
                this.ethereum = window.ethereum;
                const accounts = await this.ethereum.request({method: 'eth_accounts'});
                if (accounts.length !== 0) {
                    await commit('setAccount', accounts[0]);
                    await dispatch('checkAccessPassBalances');
                    await dispatch('resetError');
                    return 1;
                } else {
                    await commit('setAccount', null);
                    await dispatch('checkAccessPassBalances');
                    //await commit('setError', 'no-accounts');
                    return 0;
                }
            }
        },
        /**
         * request that the user selects one or more MetaMask
         * accounts to connect with
         */
        async connectMetaMaskAccount({ commit, dispatch }, connect) {
            try {
                this.ethereum = window.ethereum;
                if (!this.ethereum) {
                    commit('setError', 'no-metamask');
                    commit('setHasMetaMask', false)
                    return;
                }
                if (!(await dispatch('checkIfAccountIsConnected')) && connect) {
                    await dispatch('requestAccountAccess');
                }
                await dispatch('checkConnectedChain');
            } catch (error) {
                commit('setError', 'request-rejected');
            }
        },
        /**
         * request that the user selects one or more MetaMask
         * accounts to connect with
         */
        async disconnectAccount({ commit, dispatch }) {
            if (this.state.connectedProvider === 'walletconnect') {
                console.log('TODO: trigger disconnect');
            }
            await commit('setAccount', null);
            await dispatch('checkAccessPassBalances');
        },
        /**
         * if user has no connected MetaMask account,
         * request
         */
        async requestAccountAccess({ commit, dispatch }) {
            this.ethereum = window.ethereum;
            const accounts = await this.ethereum.request({
                method: 'eth_requestAccounts',
            });
            await commit('setAccount', accounts[0]);
            await dispatch('checkAccessPassBalances');
        },



        /**
         * ============ CONTRACT INTERACTIONS =====================
         */
        /**
         * INITIALIZE
         */
        async initializeContracts({dispatch, commit}) {
            if (!(await dispatch('getABIs'))) {
                await commit('setAbiSuccess', false);
            } else {
                await commit('setAbiSuccess', true);
            }
        },
        /**
         * CALL
         *
         * determine if there is an active campaign
         */
        async getCampaignState({dispatch, commit}) {
            let mojoHeadsContract = await dispatch('getMojoHeadsContract');
            try {
                await mojoHeadsContract.getCampaignAvailableHashCount(this.state.chain.currentCampaignId).then(async (count) => {

                    await mojoHeadsContract.getCampaign(this.state.chain.currentCampaignId).then(async (campaign) => {

                        let accessPassAddress = String(campaign.accessPassAddress);
                        await commit('setAccessPassAddress', accessPassAddress);

                        await mojoHeadsContract.getCampaignPrice(this.state.chain.currentCampaignId).then(async (pricing) => {

                            let vipId = campaign.vipSalePassId ? parseInt(String(campaign.vipSalePassId)) : 0;
                            let priorityId = campaign.preSalePassId ? parseInt(String(campaign.preSalePassId)) : 0;
                            let maxPresaleTokens = campaign.maxPresaleTokens ? parseInt(String(campaign.maxPresaleTokens)) : 0;
                            let maxOngoingTokens = campaign.maxOngoingTokens ? parseInt(String(campaign.maxOngoingTokens)) : 0;
                            let vipPrice = pricing.unitPriceVipSale ? ethers.utils.formatEther(pricing.unitPriceVipSale) : 0;
                            let vipPriceRaw = pricing.unitPriceVipSale ? pricing.unitPriceVipSale : 0;
                            let priorityPrice = pricing.unitPricePresale ? ethers.utils.formatEther(pricing.unitPricePresale) : 0;
                            let priorityPriceRaw = pricing.unitPricePresale ? pricing.unitPricePresale : 0;
                            let publicPrice = pricing.unitPriceStartPublicSale ? ethers.utils.formatEther(pricing.unitPriceStartPublicSale) : 0;
                            let publicPriceRaw = pricing.unitPriceStartPublicSale ? pricing.unitPriceStartPublicSale : 0;
                            let totalTokens = campaign.maxSupply ? parseInt(String(campaign.maxSupply)) : 0;
                            let tokensRemaining = count ? parseInt(String(count)) : 0;

                            let campaignState = this.state.campaignStates[campaign.state];

                            await commit('setCurrentCampaign', {
                                state: campaignState,
                                accessPassAddress: accessPassAddress,
                                vipId: vipId,
                                priorityId: priorityId,
                                totalTokens: totalTokens,
                                tokensRemaining: tokensRemaining,
                                vipPrice: vipPrice,
                                vipPriceRaw: vipPriceRaw,
                                priorityPrice: priorityPrice,
                                priorityPriceRaw: priorityPriceRaw,
                                publicPrice: publicPrice,
                                publicPriceRaw: publicPriceRaw,
                                maxPresaleTokens: maxPresaleTokens,
                                maxOngoingTokens: maxOngoingTokens
                            });

                            //check access pass balances for good measure
                            await dispatch('checkAccessPassBalances');

                            console.log('currentCampaign', this.state.currentCampaign);
                        });
                    });
                });
            } catch (e) {
                console.log('err', e);
                return null;
            }
        },
        /**
         * CALL
         *
         * determine whether active account has a balance for either
         * of the access pass tokens (VIP or priority)
         */
        async checkAccessPassBalances({dispatch, commit}) {
            if (this.state.account !== null && this.state.currentCampaign !== null) {
                try {
                    /**
                     * TODO - if we ever add more passes we go back to dynamically
                     * specifying the tokenId for the presale passes, otherwise
                     * we will stick to hard coding them here.
                     * @type {number}
                     */
                    let vipId = 1 //this.state.currentCampaign.vipId;
                    let priorityId = 2 //this.state.currentCampaign.priorityId
                    let accessPassContract = await dispatch('getAccessPassContract');
                    await accessPassContract.balanceOf(this.state.account, vipId).then(async (vipBalance) => {

                        if (typeof (vipBalance) !== 'undefined') {
                            const bal = ethers.utils.formatUnits(String(vipBalance), 0);
                            await commit('setVipPasses', parseInt(bal));
                        } else {
                            await commit('setVipPasses', 0);
                        }

                        if (typeof (priorityId) !== 'undefined') {
                            await accessPassContract.balanceOf(this.state.account, priorityId).then(async (priorityBalance) => {
                                const bal = ethers.utils.formatUnits(priorityBalance, 0);
                                await commit('setPriorityPasses', parseInt(bal));
                                return 1;
                            });
                        } else {
                            await commit('setPriorityPasses', 0);
                            return 1;
                        }
                    });
                } catch (e) {
                    await commit('setVipPasses', 0);
                    await commit('setPriorityPasses', 0);
                    return 0;
                }
            } else {
                await commit('setVipPasses', 0);
                await commit('setPriorityPasses', 0);
                return 0;
            }
        },

        /**
         * Set Provider
         *
         * enable window.web3
         */
        async getWeb3Provider({ commit }) {
            try {
                if (this.state.connectedProvider === 'walletconnect') {
                    if (this.state.web3 === null) {
                        const provider = new WalletConnectProvider({
                            infuraId: this.state.chain.infuraKey,
                        });
                        await provider.enable();
                        let mem = new Web3(provider)
                        await commit('setWeb3', mem)
                        return mem
                    }
                } else {
                    let mem = new Web3(window.ethereum)
                    await commit('setWeb3', mem)
                    return mem
                }
            } catch (e) {
                console.log('w3 error', e);
                return null;
            }
        },
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        /**
         * TRANSACTION
         *
         * initiate mint transaction
         */
        async mint({ commit, dispatch }, amountToMint) {
            if (this.state.hasMetaMask) {
                await commit('setMinting', true);
                try {
                    await dispatch('checkConnectedChain') //this attemptss to get user synced if need be
                    await dispatch('getWeb3Provider').then( async w3 => {
                        await dispatch('sleep', 1000).then( async res => {
                            console.log('res')
                            console.log(res)
                            if (w3.currentProvider.chainId !== this.state.chain.networkId && w3.currentProvider.chainId !== this.state.chain.chainId) {
                                console.log('wrong chain')
                                console.log(w3.currentProvider.chainId)
                                await commit('setMinting', false)
                                await commit('setError', 'Reload to try again. ' + w3.currentProvider.chainId)
                            } else {

                                let amountToBuy = 0;
                                if (amountToMint !== null && typeof (amountToMint) !== 'undefined') {
                                    amountToBuy = amountToMint;
                                }

                                /**
                                 * dynamic price lookup
                                 * @type {*|number}
                                 */
                                let costToMint = ethers.BigNumber.from(0);
                                if (this.state.currentCampaign.state === 'ONGOING') {
                                    costToMint = this.state.currentCampaign.publicPriceRaw.mul(amountToBuy)
                                } else {
                                    for (let i = 0; i < amountToBuy; i++) {
                                        if (i < this.state.vipPasses) {
                                            costToMint = costToMint.add(this.state.currentCampaign.vipPriceRaw)
                                        } else {
                                            costToMint = costToMint.add(this.state.currentCampaign.priorityPriceRaw)
                                        }
                                    }
                                }

                                if (amountToBuy === 0 || costToMint === 0) {
                                    await commit('setMinting', false);
                                    await commit('setError', 'Error calculating final cost.');
                                } else {
                                    const mhABI = JSON.stringify(this.state.mojoHeads)
                                    let mhContract = new this.state.web3.eth.Contract(
                                        JSON.parse(mhABI),
                                        this.state.chain.primaryContractAddress
                                    );
                                    mhContract.methods.mint(this.state.chain.currentCampaignId, amountToBuy, this.state.account).send({
                                        from: this.state.account,
                                        value: costToMint
                                    })
                                        .on('transactionHash', async (result) => {
                                            await commit('setMinting', false);
                                            await commit('setTx', result);
                                        })
                                        .on('error', async (error) => {
                                            if (this.state.minting) { //once minting has completed, we don't want to push errors anymore
                                                console.log('rpc error', error);
                                                await commit('setMinting', false);
                                                await dispatch('processRpcError', error);
                                            }
                                        });
                                }
                            }
                        })
                    })
                } catch (e) {
                    console.log('mint error', e);
                    await commit('setMinting', false);
                    await commit('setError', 'Please disconnect and login again.');
                }
            } else {
                await commit('setMinting', false);
                await commit('setError', 'MetaMask not installed.');
            }
        },

        async adminMint({ commit, dispatch }, amountToMint) {
            if (this.state.hasMetaMask) {
                await commit('setMinting', true);
                try {
                    await dispatch('checkConnectedChain') //this attemptss to get user synced if need be
                    await dispatch('getWeb3Provider').then( async w3 => {
                        await dispatch('sleep', 1000).then(async res => {
                            console.log('res')
                            console.log(res)
                            if (w3.currentProvider.chainId !== this.state.chain.networkId && w3.currentProvider.chainId !== this.state.chain.chainId) {
                                console.log('wrong chain')
                                console.log(w3.currentProvider.chainId)
                                await commit('setMinting', false)
                                await commit('setError', 'Reload to try again. ' + w3.currentProvider.chainId)
                            } else {

                                let amountToBuy = 0;
                                if (amountToMint !== null && typeof (amountToMint) !== 'undefined') {
                                    amountToBuy = amountToMint;
                                }

                                /**
                                 * dynamic price lookup
                                 * @type {*|number}
                                 */
                                let costToMint = ethers.BigNumber.from(0);
                                if (this.state.currentCampaign.state === 'ONGOING') {
                                    costToMint = this.state.currentCampaign.publicPriceRaw.mul(amountToBuy)
                                } else {
                                    for (let i = 0; i < amountToBuy; i++) {
                                        if (i < this.state.vipPasses) {
                                            costToMint = costToMint.add(this.state.currentCampaign.vipPriceRaw)
                                        } else {
                                            costToMint = costToMint.add(this.state.currentCampaign.priorityPriceRaw)
                                        }
                                    }
                                }

                                if (amountToBuy === 0 || costToMint === 0) {
                                    await commit('setMinting', false);
                                    await commit('setError', 'Error calculating final cost.');
                                } else {
                                    const mhABI = JSON.stringify(this.state.mojoHeads)
                                    let mhContract = new this.state.web3.eth.Contract(
                                        JSON.parse(mhABI),
                                        this.state.chain.primaryContractAddress
                                    );
                                    mhContract.methods.adminMint(this.state.chain.currentCampaignId, amountToBuy, this.state.account).send({
                                        from: this.state.account,
                                        value: costToMint
                                    })
                                        .on('transactionHash', async (result) => {
                                            await commit('setMinting', false);
                                            await commit('setTx', result);
                                        })
                                        .on('error', async (error) => {
                                            console.log('rpc error', error);
                                            await commit('setMinting', false);
                                            await dispatch('processRpcError', error);
                                        });
                                }
                            }
                        })
                    })
                } catch (e) {
                    console.log('mint error', e);
                    await commit('setMinting', false);
                    await commit('setError', 'Please disconnect and login again.');
                }
            } else {
                await commit('setMinting', false);
                await commit('setError', 'MetaMask not installed.');
            }
        },


        /**
         * HELPERS
         */
        async resetError({commit}) {
            commit('setError', null)
        },
        async getABIs({commit}) {
            try {
                return await fetch(`/frontend/abis/MintPass.json`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then((res) => {
                    return res.json();
                }).then(async (res) => {
                    await commit('setMintPass', res);
                    return await fetch(`/frontend/abis/MojoHeads.json?v=1.0.0`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }).then((res) => {
                        return res.json();
                    }).then(async (res) => {
                        await commit('setMojoHeads', res);
                        return 1;
                    });
                })
            } catch (e) {
                return 0;
            }
        },
        async getAccessPassContract() {
            const mpABI = JSON.stringify(this.state.mintPass)

            return new ethers.Contract(
                this.state.accessPassAddress,
                JSON.parse(mpABI),
                new ethers.providers.JsonRpcProvider(this.state.chain.provider)
            );
        },
        async getMojoHeadsContract() {
            const mhABI = JSON.stringify(this.state.mojoHeads)
            return new ethers.Contract(
                this.state.chain.primaryContractAddress,
                JSON.parse(mhABI),
                new ethers.providers.JsonRpcProvider(this.state.chain.provider)
            );
        },
        async getIntFromBigNumber(n) {
            const raw = String(n);
            const bn = ethers.utils.parseEther(raw);
            return ethers.utils.parseUnits(bn.toString()).toString();
        },
        async processRpcError({ commit }, e) {
            const err = String(e);
            if (err.includes('All NFTs are minted.')) {
                await commit('setError', 'All NFTs in this group are minted out.');
            } else if (err.includes('Campaign is not ready yet')) {
                await commit('setError', 'Campaign is not ready yet.');
            } else if (err.includes('insufficient funds')) {
                await commit('setError', 'Insufficient funds for the transaction.')
            } else if (err.includes('AccessControl')) {
                await commit('setError', 'Access Denied.');
            } else {
                await commit('setError', 'Transaction could not be completed.');
            }
        }
    },
});
