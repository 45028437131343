<template>

	<div id="mint-area">

        <!-- Public -->

        <div class="row">

            <div class="image">
                <img src="https://cdn.joypixels.com/uploads/mh-public-1600.jpg" />
            </div>

            <div class="detail">
                <span>April 8th at 10a PST (5p UTC)</span>
                <h3>Public Sale</h3>
                <p>No pass required. Max {{ $parent.store.state.currentCampaign.maxOngoingTokens }} MojoHeads per tx.</p>
                <b>{{ $parent.store.state.currentCampaign.publicPrice }} ETH</b>
            </div>

            <div class="mint">
                <a
                    v-if="true"
                    class="button agrandir custom style:black size:normal align:center"
                    :class="{ 'state:disabled': $parent.store.state.currentCampaign.tokensRemaining === 0 || !$parent.publicMintAvailable }"
                    @click="$parent.publicMintAvailable && $parent.store.state.currentCampaign.tokensRemaining > 0 && $parent.mintConfirm('public')">
                    <span>{{ $parent.publicMintAvailable ? 'Mint' : 'Mint Inactive' }}</span>
                </a>
                <!-- <span>You can mint a MojoHead!</span> -->
                <div v-if="$parent.store.state.currentCampaign.tokensRemaining === 0">
                    <span class="alert" v-if="$parent.store.state.currentCampaign.state !== 'PENDING'">
                        MINTED OUT
                    </span>
                    <span class="alert" v-if="$parent.store.state.currentCampaign.state === 'PENDING'">
                        MINTING SOON
                    </span>
                </div>
                <div v-if="$parent.store.state.currentCampaign.tokensRemaining > 0">
                    <span v-if="$parent.publicMintAvailable && $parent.store.state.account === null">Click to connect wallet and mint</span>
                </div>
            </div>

        </div>

        <!-- VIP -->

        <div class="row">

            <div class="image">
                <video autoplay loop muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover"
                       style="background-image:url('https://cdn.joypixels.com/uploads/mh-vip-pass.jpg')">
                    <source src="https://cdn.joypixels.com/uploads/mh-vip-pass.webm"
                            type="video/webm">
                    <source src="https://cdn.joypixels.com/uploads/mh-vip-pass.mp4"
                            type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                </video>
            </div>

            <div class="detail">
                <span>April 5th at 10a PST (5p UTC)</span>
                <h3>VIP Access</h3>
                <p>Must redeem pass within 72hrs of start time.</p>
                <b>{{ $parent.store.state.currentCampaign.vipPrice }} ETH</b>
            </div>

            <div class="mint">
                <!--<span v-if="$parent.vipPasses > 0">Passes: {{ $parent.vipPasses }}</span>-->
                <a
                v-if="true"
                class="button agrandir custom style:black size:normal align:center"
                :class="{ 'state:disabled': $parent.store.state.currentCampaign.tokensRemaining === 0 || $parent.vipPasses < 1 || !$parent.vipMintAvailable }"
                @click="$parent.vipPasses > 0 && $parent.vipMintAvailable && $parent.mintConfirm('vip')">
                    <span>{{ $parent.vipMintAvailable ? 'Mint' : 'Mint Inactive' }}</span>
                </a>
                <div v-if="$parent.store.state.currentCampaign.tokensRemaining === 0">
                    <span class="alert" v-if="$parent.store.state.currentCampaign.state === 'PRESALE' || $parent.store.state.currentCampaign.state === 'ONGOING'">
                        MINTED OUT
                    </span>
                    <span class="alert" v-if="$parent.store.state.currentCampaign.state === 'PENDING' || $parent.store.state.currentCampaign.state === 'READY'">
                        MINTING SOON
                    </span>
                </div>
                <div v-if="$parent.store.state.currentCampaign.tokensRemaining > 0">
                    <span v-if="$parent.vipMintAvailable && $parent.store.state.account === null">Connect wallet to continue</span>
                    <span v-if="$parent.store.state.account !== null">You have {{ $parent.vipPasses }} VIP pass{{ $parent.vipPasses !== 1 ? 'es' : '' }}</span>
                </div>
            </div>

        </div>

        <!-- Priority -->

        <div class="row">

            <div class="image">
                <video autoplay loop muted="" playsinline="" data-wf-ignore="true" data-object-fit="cover"
                       width="100%"
                       style="background-image:url('https://cdn.joypixels.com/uploads/mh-priority-pass.jpg')">
                    <source src="https://cdn.joypixels.com/uploads/mh-priority-pass.webm"
                            type="video/webm">
                    <source src="https://cdn.joypixels.com/uploads/mh-priority-pass.mp4"
                            type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                </video>
            </div>

            <div class="detail">
                <span>April 6th at 10a PST (5p UTC)</span>
                <h3>Priority Access</h3>
                <p>Must redeem pass within 48hrs of start time.</p>
                <b>{{ $parent.store.state.currentCampaign.priorityPrice }} ETH</b>
            </div>

            <div class="mint">
                <!--<span v-if="$parent.priorityPasses > 0">Passes: {{ $parent.priorityPasses }}</span>-->
                <a
                v-if="true"
                class="button agrandir custom style:black size:normal align:center"
                :class="{ 'state:disabled': $parent.store.state.currentCampaign.tokensRemaining === 0 || $parent.priorityPasses < 1 || !$parent.priorityMintAvailable }"
                @click="$parent.priorityPasses > 0 && $parent.priorityMintAvailable && $parent.mintConfirm('priority')">
                    <span>{{ $parent.priorityMintAvailable ? 'Mint' : 'Mint Inactive' }}</span>
                </a>
                <div v-if="$parent.store.state.currentCampaign.tokensRemaining === 0">
                    <span class="alert" v-if="$parent.store.state.currentCampaign.state === 'PRESALE' || $parent.store.state.currentCampaign.state === 'ONGOING'">
                        MINTED OUT
                    </span>
                    <span class="alert" v-if="$parent.store.state.currentCampaign.state === 'PENDING'">
                        MINTING SOON
                    </span>
                </div>
                <div v-if="$parent.store.state.currentCampaign.tokensRemaining > 0">
                    <span v-if="$parent.priorityMintAvailable && $parent.store.state.account === null">Connect wallet to continue</span>
                    <span v-if="$parent.store.state.account !== null">You have {{ $parent.priorityPasses }} Priority pass{{ $parent.priorityPasses !== 1 ? 'es' : '' }}</span>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

export default {
    name: 'MintArea',
    components: {

    },

    data: () => {
        return {

        }
    },

    mounted () {

        console.log('mint area mounted');

    },

    methods: {

    }
}
</script>

<style lang="scss">

#mint-area {
    margin: 0 auto;
    padding: 4em 0;
    max-width: 990px;
    border-bottom: 2px solid #000;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2em 0;


    .row {
        display: grid;
        grid-template-columns: 185px 1fr 230px;
        grid-gap: 2em 4em;
        align-items: center;

        &:not(:last-child)::after {
            content: "";
            border-bottom: 2px solid #000;
            grid-column: 2 / 4;
        }

        .detail {
            display: grid;
            grid-auto-rows: min-content;
            grid-gap: .5em;

            span, p {
                font-family: "Foundersgrotesk";
            }

            h3, b {
                font-family: "Agrandir Style";
            }

            span {
                font-size: 1.2em;
                /*padding-left: .5em;*/
                /*background: #eee;*/
                /*width: auto;*/
                /*border-left: 3px solid #ddd;*/
                /*border-bottom: 3px solid #ddd;*/
                margin-bottom: .4em;
            }

            h3 {
                margin: 0;
                font-size: 1.6em;
            }

            p {
                margin: 0;
                font-size: 1.2em;
            }

            b {
                font-size: 1.25em;
            }
        }

        video, img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .mint {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 1em;

            text-align: center;

            > span {
                color: #999;
                font-family: "Foundersgrotesk";
            }

            > div {
                > span {
                    color: #999;
                    font-family: "Foundersgrotesk";
                }
            }
        }
    }

    @media ( max-width: 900px ) {
        .row {
            grid-auto-rows: min-content;

            .detail {
                grid-column: 2 / 4;
            }

            .mint {
                grid-column: 1 / 4;
            }

            &::after {
                grid-column: 1 / 4 !important;
            }
        }
    }

    @media ( max-width: 600px ) {
        .row {
            grid-template-columns: 1fr;
            grid-gap: 2em;

            .image {
                grid-column: 1 / 4;
                max-width: 260px;
                margin: 0 auto;
            }

            .detail {
                grid-column: 1 / 4;
                grid-gap: .1em;

                span {
                    border: none;
                    padding: 0;
                    margin: 0;
                    grid-row: 2 / 3;
                    color: #999;
                    font-size: 1em;
                }

                h3 {
                    margin-bottom: .5em;
                }
            }

            .mint {
                grid-column: 1 / 4;
            }

            &::after {
                grid-column: 1 / 4 !important;
            }
        }
    }
}

</style>
