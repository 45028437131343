<template>

	<header id="main">
        <a href="https://mojoheads.com" class="logo"><img src="../assets/images/logo-disco.svg" /></a>

        <div class="bar align:right">
            <a v-if="true"
            class="button agrandir custom color:white shape:round size:normal align:center"
            @click="$parent.metaMaskModal.show = true">
                <span v-if="!$parent.isSignedIn">Connect Wallet</span>
                <span v-if="$parent.isSignedIn">{{ $parent.firstSixOfAddress }}</span>
            </a>
        </div>

        <div class="text">
            <h1 class="agrandir">Mint a MojoHead!</h1>
            <p class="poppins">Every MojoHead NFT is a unique 1-of-1 design and built in partnership with each artist. There are 28 artists x 21 expressions for a max supply of 588 NFTs for this release.</p>
        </div>

        <div class="heroes" style="margin-bottom: -2px;">
            <!--<img src="../assets/images/hero-1.gif" />-->
            <lottie-animation
            path="frontend/lottie/group-c_1.json"
            />

            <div class="minted agrandir" :class="{ visible: $parent.mint.loaded }">
                <h4>{{ parseInt($parent.mint.numberMintedFormatted) }} of {{ parseInt($parent.mint.numberMintableFormatted) }} Minted</h4>
                <div class="minted-bar"><div class="minted-bar-inner" :class="{ pause: !$parent.mint.loaded }" :style="{ width: $parent.mint.percentMinted + '%' }"></div></div>
            </div>

            <!--<img src="../assets/images/hero-2.gif" />-->
            <lottie-animation
                path="frontend/lottie/group-c_2.json"
            />
        </div>

    </header>

</template>

<script>

import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
    name: 'Header',
    components: {
        // SocialIcons
        LottieAnimation
    },

    data: () => {
        return {

        }
    },

    mounted () {

        /*setTimeout(() => {
            this.mint.loaded = true;
        }, 2000);*/

    },
}
</script>

<style lang="scss">

header#main {
    padding:  1em 2em 0 2em;
    background-image:  linear-gradient(90deg,#ff2c84,#ff6c50);
    color:  #fff;

    font-size: 16px;
    font-family:  sans-serif;

    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 500px));
    grid-template-rows: 60px auto;
    grid-auto-rows: min-content;
    justify-content: center;
    align-items: center;


    .logo {
        justify-content: left;
    }

    .text {
        grid-column:  1 / 3;
        text-align: center;
        align-content: center;
        padding: 4em 0 1em 0;

        display:  grid;
        grid-gap:  1.75em;

        h1, p {
			width: 70vw;
			max-width: 700px;
			min-width: 280px;
			margin: 0 auto;
        }

        p {
            line-height:  1.9rem;
            font-size: 1.45rem;
        }
    }

    .heroes {
        grid-column: 1 / 3;

        display: grid;
        grid-template-columns: minmax(100px, 250px) minmax(300px, 1fr) minmax(100px, 250px);
        /*justify-content: space-between;*/
        grid-gap: 1em;
        align-items: center;

        img {
            width: 100%;
            max-width: 250px;
        }

        .minted {
            opacity: 0;
            padding: 1.5em 0;
            transform: scale(.7);

            transition: all 1s cubic-bezier(0.25, 0.1, 0, 0.99);

            &.visible {
                opacity: 1;
                /*padding: 0;*/
                transform: scale(1);
            }

            h4 {
                margin-bottom: .625em;
                font-size: 1.125em;
                text-align: center;
            }

            .minted-bar {
                width: 100%;
                background: #fff;
                border-radius: 5em;
                padding: .2em;

                div {
                    width: 0;
                    height: .8em;
                    border-radius: 5em;
                    background: #000;

                    transition: width 2s cubic-bezier(0.25, 0.1, 0, 0.99);

                    /*
                        Hold off on the animation until the mint data is loaded.
                        Is this the only good use of !important?!
                    */

                    &.pause {
                        width: 0 !important;
                    }
                }
            }
        }
    }

    .button {
        font-size: .95em;
        text-align: center;
        height: 2.9em;
    }

    @media ( max-width: 650px ) {
        .heroes {
            grid-template-columns: minmax(300px, 1fr);

            div:not(.minted):not(.minted-bar):not(.minted-bar-inner) {
                display: none;
            }

            .minted {
                /*grid-column: 1 / 4;*/
                padding-bottom: 4em;
            }
        }
    }

    @media ( max-width:  500px ) {
        font-size: 16px;
        padding: 1em;

        .logo img {
            max-width: 150px;
        }

        .button {
            font-size: .8em;
        }

        h1 {
            font-size: 1.75em;
        }
    }

}

</style>
