import { createApp } from 'vue'
import App from './App.vue'
//import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs
import store from './store'

// import createRouter from './router';

const app = createApp(App).use(store);
// const router = createRouter();



import './tecto-ui/styles/base/global.scss'
import './tecto-ui/styles/base/pwa.scss'
import './tecto-ui/styles/themes/new.scss'

import './tecto-ui/styles/components/modal.scss'
import './tecto-ui/styles/components/toast.scss'
import './tecto-ui/styles/components/icon.scss'
import './tecto-ui/styles/components/button.scss'
import './tecto-ui/styles/components/input/_all.scss'
import './tecto-ui/styles/components/menu.scss'
import './tecto-ui/styles/layout/button-group.scss'
import './tecto-ui/styles/layout/grid.scss'

import './tecto-ui/styles/layout/bar.scss';
import './tecto-ui/styles/helpers/noscrollbars.scss';
import './tecto-ui/styles/helpers/vcen.scss';
import './tecto-ui/styles/elicons/_base.scss';
import './tecto-ui/styles/elicons/close.scss';
import './tecto-ui/styles/elicons/menu.scss';
import './tecto-ui/styles/elicons/ellipsis.scss';
import './tecto-ui/styles/elicons/circle.scss';
import './tecto-ui/styles/elicons/paint-bucket.scss';
import './tecto-ui/styles/elicons/chevron.scss';
import './tecto-ui/styles/elicons/plus.scss';
import './tecto-ui/styles/elicons/check.scss';
import './tecto-ui/styles/elicons/face.scss';

import './main.scss';


// app.use(router);
app.mount('#app');
// createApp(App).mount('#app')
