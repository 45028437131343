<template>

    <Toast />

    <div class="grid-a">
        <Header />
        <main>

            <div id="header-sticky" class="agrandir regular" v-if="campaignLoaded">
                <h3>{{ stickyHeaderText }}</h3>
                <span class="foundersgrotesk" style="font-weight: bold;" v-if="!publicMintAvailable && isSignedIn">You have {{ totalPasses }} Access Pass{{ totalPasses !== 1 ? 'es' : '' }}</span>
                <span class="foundersgrotesk" v-if="publicMintAvailable && store.state.currentCampaign.tokensRemaining > 0">No pass required</span>
                <span class="foundersgrotesk" v-if="publicMintAvailable && store.state.currentCampaign.tokensRemaining === 0">
                    <!--TODO: uncomment to revert: Thank you!-->
                </span>
            </div>

            <MintArea v-if="campaignLoaded"></MintArea>

            <div class="loading-ui foundersgrotesk" style="width: 100%"
            v-else>
                <img src="./assets/images/loading.gif" />
            </div>

        </main>

        <div id="footer-text">
            <span v-if="true">
                Buy a Pass on
                <a href="https://opensea.io/collection/mojopasses" target="_blank" class="bar">
                    OpenSea
                    <img src="https://uploads-ssl.webflow.com/61150b8550520014467f0997/6165766d23f649c30431eff2_opensea.svg" />
                </a>
            </span>
            <span v-if="false">
                Adopt a MojoHead on
                <a href="https://opensea.io/collection/mojoheads" target="_blank" class="bar">
                    OpenSea
                    <img src="https://uploads-ssl.webflow.com/61150b8550520014467f0997/6165766d23f649c30431eff2_opensea.svg" />
                </a>
            </span>
            <p>
                Mint passes are designed by
                <a href="http://twitter.com/Nopkarian" target="_blank" class="link-3">@Nopkarian</a>, one of our own MojoHeads!
            </p>
        </div>

        <Footer />

    </div>

    <AtmaModal
    v-model:show="metaMaskModal.show"
    class="metamask-modal"
    width="500px">

        <template v-slot:header>
            <div class="bar align:center">
                <img src="./assets/images/head-logo.svg" />
            </div>
        </template>

        <h2 class="agrandir" v-if="!isSignedIn">Connect Wallet</h2>
        <h2 class="agrandir" v-if="isSignedIn">Wallet Connected</h2>

        <p v-if="!isSignedIn">Please connect via MetaMask or WalletConnect</p>
        <p v-if="isSignedIn">
            You are connected via {{ store.state.connectedProvider === 'walletconnect' ? 'WalletConnect' : 'MetaMask' }}.
            To fully disconnect, first do so through {{ store.state.connectedProvider === 'walletconnect' ? 'your connection provider' : 'MetaMask' }}, then click below to clear the session.
        </p>

        <!-- input -->

        <template v-slot:footer>
            <div class="bar align:stack">

                <a
                    v-if="!isSignedIn"
                    class="button agrandir style:black align:apart custom"
                    @click="signIn('metamask')">
                        <span>MetaMask</span>
                        <span class="icon icon:metamask"><img src="./assets/images/meta.png" /></span>
                </a>

                <a
                    v-if="!isSignedIn"
                    class="button agrandir style:black align:apart custom"
                    @click="signIn('walletconnect')">
                        <span>WalletConnect</span>
                        <span class="icon icon:metamask"><img src="./assets/images/walletconnect.svg" /></span>
                </a>

                <div class="bar" v-if="isSignedIn">
                    <a
                    class="button agrandir style:black align:apart custom"
                    @click="signOut()">
                        <span>Disconnect</span>
                        <span class="icon"><img src="./assets/images/icon-login-white.svg" /></span>
                    </a>

                    <!-- <a
                    v-if="isSignedIn"
                    class="button agrandir style:black align:apart custom"
                    @click="metaMaskModal.show = false">
                        <span>Continue</span>
                    </a> -->
                </div>

            </div>
        </template>
    </AtmaModal>


    <!-- Loading Modal -->

    <AtmaModal
    v-model:show="minting.loading"
    class="metamask-modal loading-modal"
    :persistent="true"
    width="auto">

        <h2 v-if="minting.error" class="agrandir">Error</h2>
        <h2 v-if="minting.transactionHash" class="agrandir">Minting Underway</h2>


        <div class="loading-ui foundersgrotesk">

            <div v-if="!minting.transactionHash && !minting.error">
                <img src="./assets/images/loading.gif" />
                <span>Confirming transaction...</span>
            </div>

            <span class="alert" v-if="minting.error">{{ minting.error }}</span>
            <div v-if="minting.transactionHash">
                Transaction Hash: <br />
                <div class="input:text size:small">
                    <input type="text" autocomplete="off" v-model="minting.transactionHash" style="grid-column: 1/4" />
                </div>
            </div>
            <span v-if="minting.transactionHash">Use Etherscan to check transaction status.</span>
        </div>

        <div class="bar align:stack" v-if="minting.transactionHash">
            <a
            class="button agrandir style:black custom"
            :href="store.state.chain.etherscan + minting.transactionHash"
            target="_blank">
                <span>Etherscan</span>
            </a>

            <a
            class="button agrandir style:black custom"
            :href="'https://opensea.io/' + store.state.account"
            target="_blank"
            style="color:#fff">
                <span>OpenSea</span>
            </a>

            <a
            class="button agrandir style:logout custom"
            @click="reload">
                <span>Done</span>
            </a>
        </div>

        <div class="bar align:stack" v-if="minting.error">
            <a
            class="button agrandir style:logout custom"
            @click="reload">
                <span>Try Again</span>
            </a>
        </div>

    </AtmaModal>

    <TermsOfServiceModal v-model:show="showToS" />

    <MintModals ref="mintModals" />

</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import MintArea from './components/MintArea.vue';
import MintModals from './components/MintModals.vue';
import TermsOfServiceModal from './components/TermsOfServiceModal.vue';
import AtmaModal from './tecto-ui/vue/AtmaModal.vue';
import Toast from './tecto-ui/vue/Toast.vue';
import ToastService from './tecto-ui/vue/services/ToastService.js';
//import axios from 'axios';
import {useStore} from "vuex";

export default {
    name: 'App',

    components: {
        Header,
        Footer,
        MintArea,
        MintModals,
        AtmaModal,
        Toast,
        TermsOfServiceModal,
    },

    data: () => {
        return {
            metaMaskModal: {
                show: false,
                manual: {
                    show: false,
                    error: false,
                },
                after: null
            },
            store: null,
            mint: {
                loaded: false,
                numberMinted: 0,
                numberMintable: 0,
                numberMintedFormatted: '',
                numberMintableFormatted: '',
                percentMinted: 0,
            },
            campaignLoaded: false,
            minting: {
                loading: false,
                error: null,
                // success: false,
                transactionHash: null,
            },
            showToS: false,
        }
    },

    watch: {
        isSignedIn: function(v) {
            //console.log(('sign in state change');
            if ( v ) {

                this.metaMaskModal.show = false;

                if (this.metaMaskModal.after !== null) {
                    console.log('open mint modal to continue');
                    this.mintConfirm(this.metaMaskModal.after);
                    this.metaMaskModal.after = null;
                }
            }
        }
    },

    computed: {
        isSignedIn() {
            if (this.store && this.store.state) {
                return this.store.state.account !== null;
            } else {
                return false;
            }
        },
        firstSixOfAddress() {
            return this.store.state.account ? this.store.state.account.substr(0, 6) + '...' : '...';
        },

        vipPasses() {
            return this.store && this.store.state ? this.store.state.vipPasses : 0;
        },

        priorityPasses() {
            return this.store && this.store.state ? this.store.state.priorityPasses : 0;
        },

        totalPasses() {
            return this.vipPasses + this.priorityPasses;
        },

        canAdminMint() {
            // if (this.store && this.store.state) {
            //     if (this.store.state.account !== null) {
            //         if (this.store.state.account.toLowerCase() === '0x790937BE495a0FD8f6C1ef79c55C1cF40aAF66c3'.toLowerCase() || this.store.state.account.toLowerCase() === '0x97a99da15fc89d96b3fbe673ffd8f69f6ce7c9b1'.toLowerCase() || this.store.state.account.toLowerCase() === '0xc244cb34C8aaA1C18d369317132Ca2e6Abf60F49'.toLowerCase()) {
            //             if (this.store.state.currentCampaign.state === 'READY') {
            //                 return true;
            //             }
            //         }
            //     }
            // }
            return false;
        },

        vipMintAvailable() {
            if (this.canAdminMint) {
                return true;
            }

            return this.store && this.store.state && this.store.state.currentCampaign.state === 'PRESALE';
        },

        priorityMintAvailable() {
            if (this.canAdminMint) {
                return true;
            }

            return this.store && this.store.state && this.store.state.currentCampaign.state === 'PRESALE' && this.store.state.currentCampaign.priorityId !== 0;
        },

        publicMintAvailable() {
            return this.store && this.store.state && this.store.state.currentCampaign.state === 'ONGOING';
        },

        maxPresaleTokens() {
            return this.store && this.store.state && this.store.state.currentCampaign.maxPresaleTokens;
        },

        maxOngoingTokens() {
            return this.store && this.store.state && this.store.state.currentCampaign.maxOngoingTokens;
        },

        stickyHeaderText () {
            let txt;

            if (this.store && this.store.state && this.store.state.currentCampaign.state !== 'PENDING' && this.store.state.currentCampaign.tokensRemaining === 0) {
                txt = 'MINTED OUT';
            } else {
                if (this.vipMintAvailable) {
                    txt = 'Presale is open';
                } else if (this.publicMintAvailable) {
                    txt = 'Public minting is open';
                } else {
                    txt = 'MINTING SOON';
                }
            }

            return txt;
        },

        isPresaleMode() {
            if (this.store && this.store.state && this.store.state.currentCampaign !== null) {
                if (this.store.state.currentCampaign.tokensRemaining === 0 || this.store.state.currentCampaign.state === 'ONGOING' || this.store.state.currentCampaign.state === 'FINISH') {
                    return false
                }
            }
            return true
        }
    },

    async mounted()
    {
        this.store = useStore();

        await this.store.dispatch('checkWalletConnectConnected');
        await this.store.dispatch('checkMetaMaskInstalled');
        await this.store.dispatch('listenForAccountChanges');
        await this.store.dispatch('initializeContracts');

        if (this.store.state.abiSuccess) {
            await this.store.dispatch('getCampaignState');

            if (this.store.state.currentCampaign !== null) {
                let artistEditions = 28;
                let totalNonArtistEditions = 560;
                let tot = totalNonArtistEditions+artistEditions;
                let rem = Math.max(this.store.state.currentCampaign.tokensRemaining-artistEditions, 0)

                tot = 588;
                rem = this.store.state.currentCampaign.state === 'PENDING' ? tot : this.store.state.currentCampaign.tokensRemaining;
                this.initMintBar(rem, tot);
                this.campaignLoaded = true;
            } else {
                ToastService.add({
                    typ: 'danger',
                    msg: 'Campaign Not Ready',
                    dur: 0,
                });
            }
        } else {
            ToastService.add({
                typ: 'danger',
                msg: 'Could Not Load ABIs',
                dur: 0,
            });
        }

        if (this.store.state.connectedProvider !== 'walletconnect') {
            if (this.store.state.hasMetaMask) {
                if ((await this.store.dispatch('checkIfAccountIsConnected'))) {
                    //console.log(('Account Detected: ', this.store.state.account);
                } else {
                    this.showToS = true
                }
            } else {
                this.showToS = true
            }
        } else {
            //ensure correct chain is connected
            await this.store.dispatch('checkConnectedChain');
        }
    },

    methods: {
        async signIn(provider) {
            //console.log(('clicked sign in')

            if (this.store.state.account) {
                console.log('ALREADY SIGNED IN');
            } else {
                if (provider === 'walletconnect') {
                    // Check if connection is already established
                    if (this.store.state.walletConnect !== null) {
                        if (this.store.state.walletConnect.connector !== null) {
                            if (!this.store.state.walletConnect.connector.connected) {
                                // create new session
                                this.store.state.walletConnect.connector.createSession();
                                this.store.dispatch('listenForAccountChanges');
                            } else {
                                await this.store.dispatch('checkWalletConnectConnected');
                            }
                        } else {
                            console.log('connection issues');
                        }
                    } else {
                        console.log('connection issues');
                    }
                } else {
                    if (this.store.state.hasMetaMask) {
                        await this.store.dispatch('checkConnectedChain');
                        if (this.store.state.error === 'chain-incorrect') {
                            //console.log(('needed correct chain');
                        } else {
                            //console.log(('should have correct chain');
                            if (!(await this.store.dispatch('checkIfAccountIsConnected'))) {

                                //console.log(('user has no connected account, request');
                                await this.store.dispatch('connectMetaMaskAccount');
                                if (!this.store.state.hasMetaMask) {
                                    //console.log(('REQUEST FAILED - MetaMask not installed');
                                } else if (this.store.state.error === 'request-rejected') {
                                    //console.log(('REQUEST FAILED - Request rejected');
                                } else if (this.store.state.error === 'chain-incorrect') {
                                    //console.log(('REQUEST FAILED - Incorrect chain');
                                } else {
                                    await this.store.dispatch('connectMetaMaskAccount', true);
                                    if (this.store.state.error === 'no-metamask') {
                                        //console.log(('no meta mask');
                                    } else if (this.store.state.error === 'request-rejected') {
                                        //console.log(('request rejected');
                                    } else {
                                        if (this.store.state.account !== null) {
                                            //console.log(('REQUEST SUCCEEDED');
                                            //console.log(('account', this.store.state.account);
                                        }
                                    }
                                }
                            } else {
                                /**
                                 * SUCCESSFUL CONNECTION
                                 */
                                ToastService.add({
                                    typ: 'success',
                                    msg: 'MetaMask Connected',
                                    dur: 3,
                                });
                            }
                        }
                    } else {
                        ToastService.add({
                            typ: 'danger',
                            msg: 'MetaMask Not Detected',
                            dur: 0,
                        });
                    }
                }
            }
        },

        initMintBar (tokensRemaining, totalTokens) {
            this.mint.numberMintable = totalTokens;
            this.mint.numberMintableFormatted = this.addCommas(totalTokens);
            this.mint.numberMinted = Math.max(totalTokens - tokensRemaining, 0);
            this.mint.numberMintedFormatted = this.addCommas(this.mint.numberMinted);
            this.mint.percentMinted = Math.round( ( ( totalTokens - tokensRemaining ) / totalTokens ) * 100 );
            this.mint.loaded = true;
        },

        addCommas (n) {
            return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        signOut () {
            this.store.dispatch('disconnectAccount');
        },

        reload () {
            window.location.reload();
        },

        mintConfirm(type) {
            let m = this.$refs.mintModals;

            if (this.store.state.account === null) {

                this.metaMaskModal.after = type; //follow up accordingly after connect
                this.metaMaskModal.show = true

            } else {

                switch ( type ) {
                    case 'vip':

                        m.qty.config = {
                            isPublic: false,
                        }
                        m.qty.continue = () => {
                            m.conf.headerText = 'Confirm Minting';
                            this.doMint();
                        }

                        break;

                    case 'priority':

                        m.qty.config = {
                            isPublic: false,
                        }
                        m.qty.continue = () => {
                            m.conf.headerText = 'Confirm Minting';
                            this.doMint();
                        }

                        break;

                    case 'public':

                        console.log('minting public')

                        m.qty.config = {
                            isPublic: true,
                        }
                        m.qty.continue = () => {
                            m.conf.headerText = 'Confirm Minting';
                            this.doMint();
                        }

                        break;
                }

                //open qty modal
                m.qty.show = true;
            }
        },

        async doMint() {
            this.$refs.mintModals.qty.show = false;

            console.log('minting');
            if (this.store.state.currentCampaign.state === 'READY') {
                console.log('campaign is in ready state meaning only admins can mint');
                this.store.dispatch('adminMint', this.$refs.mintModals.qty.num);
            } else {
                this.store.dispatch('mint', this.$refs.mintModals.qty.num);
            }
            this.minting.loading = true;
            this.waitForMinting();
        },

        waitForMinting () {
            setInterval( () => {
                if ( this.store.state !== 'minting' ) {
                    this.minting.error = this.store.state.error || null;
                    this.minting.transactionHash = this.store.state.tx || null;

                    // this.$parent.minting.loading = false;
                    // t.clearInterval();
                }
            }, 1000);
        }

    }
}
</script>

<style lang="scss">

    .grid-a {
        display:  grid;
        grid-template-columns: 1fr;
        /*grid-template-rows: repeat(auto, 1fr);*/
        grid-gap: 4.5em 0;
    }

    main {
        padding:  0 1em;

        #header-sticky {
            max-width: 990px;
            margin:  -1px auto 0 auto;
            position: sticky;
            z-index: 100;
            top:  0;
            background:  #fff;
            padding:  2em 0;
            border-bottom:  2px solid #000;

            * {
                font-size:  1.25em;
            }

            h3 {
                margin: 0;

                &.grey {
                    color: #bbb;
                }
            }

            span {
                color: #ff5b5f;
                font-size: 1.2em;
                text-align: right;
            }

            display:  grid;
            /*grid-template-columns: repeat(2, minmax(auto, 425px));*/
            grid-template-columns: auto auto;
            justify-content: space-between;
            align-items: center;
            justify-items: center;
            grid-gap: 2em;
        }

        @media ( max-width: 900px ) {
            #header-sticky {
                /*font-size: 13px;*/
                grid-template-columns: 1fr;
                align-content: center;
                grid-gap: .5em;
                padding: 1em;
            }
        }
    }

    .modal.metamask-modal {
        z-index: 1000;

        .container header {
            padding: 2em 2em 0 2em;

            img {
                width: 24px;
            }

        }

        .button.close {
            position: absolute;
            right: 2%;
            top: 2%;
            z-index: 1001;
        }

        section {
            text-align: center;
        }
    }

    .modal.loading-modal {
        .button.close {
            display: none;
        }

        .container footer {
            padding: 1em;
        }
    }

    .loading-ui {
        width: 300px;
        text-align: center;

        img {
            width: 36px;
        }

        span, b {
            display: block;
            padding: 1em;
        }
    }

    #footer-text {
        font-family: Foundersgrotesk, sans-serif;
        font-size: 1.25em;
        text-align: center;
        margin: 0 auto;
        padding: 0 40px;

        a {
            display: inline-grid;
            width: auto;
            grid-gap: .3em;
        }

        span {
            text-align:center;

            a {
                img {
                    width: 24px;
                }
            }
        }

        p {
            line-height: 1.2em;
        }
    }

</style>
