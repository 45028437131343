<template>

	<!-- Confirm -->

	<AtmaModal
    v-model:show="conf.show"
    class="metamask-modal"
    width="500px">

        <template v-slot:header>
            <div class="bar align:center">
                <img src="./../assets/images/head-logo.svg" />
            </div>
        </template>

        <h2 class="agrandir">{{ conf.headerText }}</h2>

        <!-- <p>{{ conf.messageText }}</p> -->

        <p class="alert" v-if="!isPublic">Burn {{ burnText }}?</p>
        <p class="alert" v-else>Mint {{ qty.num }} MojoHeads?</p>

        <template v-slot:footer>
            <div class="bar align:stack">

                <a
                class="button agrandir style:black custom"
                @click="conf.continue">
                    <span>Mint</span>
                </a>

                <a
                class="button agrandir style:logout custom"
                @click="conf.show = false; qty.show = true;">
                    <span>Back</span>
                </a>

            </div>

        </template>
    </AtmaModal>

    <!-- Quantity Modal -->

    <AtmaModal
    v-model:show="qty.show"
    class="metamask-modal scroll:modal"
    width="500px">

        <template v-slot:header>
            <div class="bar align:center">
                <img src="./../assets/images/head-logo.svg" />
            </div>
        </template>

        <h2 class="agrandir">How Many?</h2>

        <p>You may mint up to <b>{{ qtyMax }}</b> MojoHeads.</p>
        <!-- <p v-else>You may mint up to <b>{{ qtyMax }}</b> MojoHeads using Public Passes.</p> -->

        <QuantityChooser @update="updateQty($event)" :quantity="qty.num" :max="qtyMax" />

        <p class="alert" v-if="!isPublic">Burns {{ burnText }}</p>

        <template v-slot:footer>
            <div class="bar align:stack">

                <a
                class="button agrandir style:metamask custom"
                @click="next">
                    <span>Mint</span>
                </a>

            </div>
        </template>
    </AtmaModal>

</template>

<script>

	import AtmaModal from '../tecto-ui/vue/AtmaModal.vue';
	import QuantityChooser from './QuantityChooser.vue';

	export default {
		name: 'MintModals',

		components: {
			AtmaModal,
			QuantityChooser,
		},

		data: () => {
			return {
				qty: {
					show: false,
					num: 1,
					continue: null,
					config: {
                        isPublic: false,
                    },
				},
				conf: {
					show: false,
					headerText: 'Confirm',
					messageText: 'Continue?',
					continue: null,
				}
			}
		},

		props: {

		},

		computed: {
			burnText () {
				let vip = this.qty.num >= this.$parent.vipPasses ? this.$parent.vipPasses : this.qty.num;
				vip += ' VIP';
				let pri = this.qty.num > this.$parent.vipPasses ? ' and ' + (this.qty.num - this.$parent.vipPasses) + ' Priority Pass(es)' : '';

				return this.isPublic ? this.qty.num + ' Public' : vip + pri;
			},

			/*
				Find the max value allowed in the quantity chooser.
				Either the totalPasses or the overall max, whichever comes first.
				Use qty.isPublic to determine which overall max value to use.
			*/

			qtyMax () {
                let tot = 0;
                //presale pass logic
                if (this.$parent.store.state.currentCampaign.priorityId === 0) { //indicates that VIP passes are enabled but not priority
                    tot = Math.min(this.$parent.store.state.currentCampaign.tokensRemaining, this.$parent.vipPasses);
                } else {
                    tot = Math.min(this.$parent.store.state.currentCampaign.tokensRemaining, this.$parent.totalPasses);
                }
                //if not in presale use campaign max
				let ovr =
                    this.qty.config.isPublic ?
                    this.$parent.maxOngoingTokens :
                    this.$parent.maxPresaleTokens;
                //then between passes, remaining, and campaign max...
				return this.qty.config.isPublic ? Math.min(this.$parent.store.state.currentCampaign.tokensRemaining, ovr) : Math.min(tot, ovr);
			},

            isPublic () {
                return this.qty.config.isPublic;
            },
		},

		methods: {

			updateQty (ev) {
				this.qty.num = ev;
				this.$forceUpdate();
			},

			next () {
				this.qty.continue();
				// this.qty.show = false; this.conf.show = true
			},
		}
	}

</script>
