<template>
	
	<AtmaModal
	:show="show"
    class="metamask-modal"
    width="500px"
    :persistent="true">

        <template v-slot:header>
            <div class="bar align:center">
                <img src="./../assets/images/head-logo.svg" />
            </div>
        </template>

        <h2 class="agrandir">Terms of Service</h2>

        <p>Before minting a MojoHead, please read and agree to our general <a href="https://www.mojoheads.com/terms-conditions" target="_blank">Terms of Service</a></p>

        <template v-slot:footer>
            <div class="bar align:stack">

                <a
                v-if="!isSignedIn"
                class="button agrandir style:metamask align:center custom"
                @click="toastTermsConfirm(); close()">
                    <span>I agree to terms</span>
                </a>

            </div>
        </template>
    </AtmaModal>

</template>

<script>

	import AtmaModal from './../tecto-ui/vue/AtmaModal.vue';
	import ToastService from './../tecto-ui/vue/services/ToastService.js';
	
	export default {
		name: 'TermsOfServiceModal',

		components: {
			AtmaModal,
		},

		props: {
			show: {
				type: Boolean,
				default: false,
			}
		},

		methods: {

			close () {
				this.$emit('update:show', false);
			},

			toastTermsConfirm () {
				ToastService.add({
				typ: 'success',
				msg: 'You agreed to the terms.',
				dur: 3,
				});
			}

		},
	}

</script>

