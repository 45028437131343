<template>
    
    <div class="chooser bar align:even">
        <a 
        class="button shape:round icon-only style:outline" 
        @click="decrement"
        :class="{ 'state:disabled': qty === 1 }">
            <span class="icon elicon:plus state:active"><i></i></span>
        </a>

        <span class="agrandir">{{ qty }}</span>

        <a 
        class="button shape:round icon-only style:outline" 
        @click="increment"
        :class="{ 'state:disabled': qty === max }">
            <span class="icon elicon:plus"><i></i></span>
        </a>
    </div>

</template>

<script>
    
    export default {
        name: 'QuantityChooser',

        props: {
            quantity: {
                type: Number,
                default: 1,
            },
            max: {
                type: Number,
                default: 1,
            }
        },

        data: () => {
            return {
                qty: 1,
            }
        },

        methods: {

            increment () {
                this.qty < this.max ? this.qty++ : this.qty;
                this.$emit('update', this.qty);
            },

            decrement () {
                this.qty > 1 ? this.qty-- : this.qty;
                this.$emit('update', this.qty);
            },
        },

        mounted () {
            this.qty = this.quantity;
        }
        
    }

</script>

<style lang="scss">
    
    .chooser span {
        font-size: 2em;
    }

</style>