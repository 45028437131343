export default new class {
    constructor() {
        this.toasts = [];

        /*this.add({
            typ: 'success',
            msg: 'Mint Complete F-Yea!',
            dur: 5,
        });

        this.add({
            typ: 'danger',
            msg: 'Cannot Mint :(',
            dur: 7,
        });

        this.add({
            typ: 'danger',
            msg: 'Cannot Mint :(',
            dur: 3,
        });

        this.add({
            typ: 'success',
            msg: 'Mint Complete F-Yea!',
            dur: 9,
        });*/
    }

	add (obj) 
    {
        obj.id = Math.round(Math.random(100000000) * 100000000)
        obj.dur = obj.dur === undefined ? 3 : obj.dur;

        this.toasts.push(obj);

        let toast = this.toasts[ this.toasts.length - 1 ];

        if ( obj.dur > 1 )
        {
            toast.timer = setTimeout( () => 
            {
                toast.timer = null;
                    
                setTimeout( () => {
                    this.removeToast( toast.id );
                }, 1.5 * 1000)

            }, obj.dur * 1000);
        } else {
            toast.timer = false;
        }
    }

    removeToast (id) {
        for ( let i = 0; i < this.toasts.length; i++ ) 
        {
            if ( this.toasts[i].id === id ) {
                // this.toasts.splice(i, 1);
            }
        }
    }

    getToasts() {
        return this.toasts;
    }

}
