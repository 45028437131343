<template>
    
    <div id="toast">

        <div 
        class="toast-item br:circ"
        v-for="(toast, $index) of toasts" :key="$index"
        :class="toastOuterClasses(toast)">

            <div 
            class="glass bar br:inherit"
            :class="toastInnerClasses(toast)"
            bg>

                <div class="button status-icon">
                    <i
                    icn
                    class="icon weight:heavy"
                    :class="iconClass(toast)"><i></i></i>
                </div>

                <span class="proposition" txt>
                    {{ toast.msg }}
                </span>

                <div class="buttons button-group append:yes br:inherit">
                    <s></s>
                    <!-- <a icn class="button br:inherit icon-only">
                        <span class="icon elicon:chevron:left"><i></i></span>
                    </a> -->
                    <a 
                    icn 
                    class="button icon-only br:inherit" 
                    @click="toast.timer = null">
                        <span class="icon elicon:menu state:active"><i></i></span>
                    </a>
                </div>

                <!-- <span class="explainer" txt>
                     This is extra nonsense information... and this is really just too much! OMG stop with all the text!!
                </span> -->

            </div>

        </div>

    </div><!-- .toast-item -->

</template>

<script>

    /*
        TODO:
        - Pass a ref to Toast.vue into the service. The service will then trigger Toast to update when toasts array changes.
    */

    /*
        Stylesheet Dependencies:
        - styles/base/global.scss
        - styles/base/pwa.scss
        - styles/themes/new.scss
        - styles/layout/bar.scss
        - styles/components/toast.scss
        - styles/components/button.scss
        - styles/elicons/check.scss
        - styles/elicons/face.scss
    */

import ToastService from './services/ToastService.js';

export default {
    name: 'Toast',

    components: {
        // Button?
    },

    data: () => ({
        // toasts: [],
    }),

    props: {
        
    },

    computed: {
        toasts: function () {
            return ToastService.toasts;
        }
    },

    /*watch: {
        toasts: {
            deep: true,

            handler() {
                this.$forceUpdate();
            }
            
        }
    },*/

    mounted () 
    {
        setInterval( () => {
            // this.toasts = ToastService.toasts;
            this.$forceUpdate();
        }, 100)
    },

    methods: {

        add (obj) {
            // TODO: console error if txt is too long
            ToastService.add(obj);
        },

        /*addToast (obj) 
        {
            obj.id = Math.round(Math.random(100000000) * 100000000)

            this.toasts.push(obj);

            let toast = this.toasts[ this.toasts.length - 1 ];

            if ( obj.dur > 1 )
            {
                toast.timer = setTimeout( () => 
                {
                    toast.timer = null;
                        
                    setTimeout( () => {
                        this.removeToast( toast.id );
                    }, 1.5 * 1000)

                }, obj.dur * 1000);
            } else {
                toast.timer = null;
            }
        },

        removeToast (id) {
            for ( let i = 0; i < this.toasts.length; i++ ) 
            {
                if ( this.toasts[i].id === id ) {
                    // this.toasts.splice(i, 1);
                }
            }
        },*/

        toastOuterClasses: (toast) => ({
            't-out': toast.timer === null
        }),

        toastInnerClasses: (toast) => ({
            ['style:' + toast.typ]: true,
        }),

        iconClass (toast) {
            let cls;

            switch ( toast.typ ) {
                case 'danger':
                cls = 'face mode:sad';
                break;

                case 'success':
                cls = 'check';
                break;
            }

            return { ['elicon:' + cls]: true };
        },
    },
}
</script>
