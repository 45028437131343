<template>
    <div class="modal noscrollbars"
    v-if="showActual"
    :class="modalClass">

        <a 
        v-if="!persistent"
        class="button shape:round custom color:dark icon-only close size:normal" 
        @click="hide">
            <span class="icon"><img src="./../../assets/images/icon-close.svg" /></span>
        </a>

        <div class="backdrop" @mousedown="hideIfImpersistent" @touchstart="hideIfImpersistent">
            
        </div>

        <div class="wrap" :style="{ 'max-width': width }">
            <div class="container">
                <header>
                    <slot name="header"></slot>
                </header>
                <section>
                    <slot></slot>
                </section>
                <footer>
                    <slot name="footer"></slot>
                </footer>
            </div>
        </div>
    </div>
</template>

<script>

// User Notes:
// If modal is contained within a positioned element, or an element with CSS backdrop-filter rule applied - it will not position to body element.

export default {
    name: 'AtmaModal',

    components: {

    },

    data: () => ({
        // animateIn: false,
        showActual: false,
    }),

    props: {
        show: {
            type: Boolean,
            default: false,
        },
        // title: String,
        /*height: {
            type: String,
            default: '300px'
        },*/
        align: {
            type: String,
            default: 'mid',
        },
        width: {
            type: String,
            default: '600px',
        },
        zIndex: {
            type: Number,
            default: 10000,
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        padContent: {
            type: Boolean,
            default: true,
        },
        hideHeader: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        closeOnRouteChange: {
            type: Boolean,
            default: true,
        },
        scroll: {
            type: String,
            default: 'content',
        }
    },

    computed: {

        modalClass () {
            return {
                'show:yes': this.show,
                'hide:header': this.hideHeader,
                'hide:footer': this.hideFooter,
                ['align:' + this.align]: true,
                ['scroll:' + this.scroll]: true,
            }
        },

        modalStyle () {
            return {
                'max-width': this.width,
                zIndex: this.zIndex,
            }
        },

        /*styleOver () {
            return {
                gridTemplateColumns: 'minmax(auto, ' + this.maxWidth + ')',
            }
        },*/

        /*animationClass () {
            return {
                in: this.animateIn, 
                out: !this.animateIn,
            }
        },*/

        /*overClass () {
            let o = {'no-content': !this.hasContent}
            let o2 = this.animationClass;

            return Object.assign(o, o2);
        },*/

        /*buttonBarStyle () 
        {
            let pad;

            switch ( this.layout.type )
            {
                case 'wide':
                case 'matrix':
                case 'stacked':

                pad = false;
                break;

                default:

                pad = true;
            }

            return {'has-padding': pad};
        },*/

        /*buttonGroupClass () {
            return {
                ['is-' + this.layout.type]: true,
                'is-bundled': this.layout.bundled,
            }
        },*/

        /*layout () 
        {
            let l = this.buttonLayout.split('-'), o = {};

            o.type = l[0];

            if ( l.length > 1 )
            {
                o.bundled = true;
            }

            return o;
        }*/
    },

    

    watch: {
        show: function(val) {
            if ( val )
            {
                this.showActual = true;

                if ( !this.persistent ) {
                    window.addEventListener( 'keydown', this.handleEscape );
                }

                setTimeout( () => { /*this.animateIn = true*/ }, 10); /* Delay allows the animation to happen. Could use Vue's <transition> to solve this? */
            }
            else
            {
                this.hide();
            }
        },

        $route: function () {
            if ( this.closeOnRouteChange && this.showActual )
            {
                this.hide();
            }
        },
    },

    methods: {

        hide () {
            // this.animateIn = false;
            window.removeEventListener('keydown', this.handleEscape );
            setTimeout( () => { 
                this.$emit('update:show', false);
                this.showActual = false;
                this.$emit('hide');
            }, 1000 * .3 );
        },

        hideIfImpersistent () {
            if ( !this.persistent ) {
                this.hide();
            }
        },

        handleEscape (e) {
            if ( e.key === 'Escape' ) {
                this.hideIfImpersistent();
            }
        },

        /*filteredButtons (position) {
            let o = {}, btn;


            for ( btn in this.buttons )
            {
                if ( this.buttons[btn].position === position )
                {
                    o[btn] = this.buttons[btn];
                }
            }

            return o;
        },*/
    },

}
</script>
